<template>
  <Stack
    direction="col"
    tag="header"
    justify="between"
    align="center"
    class="w-full p-xs md:p-sm bg-mkm-yellow-default"
    gap="2xs"
  >
    <Stack align="center" class="w-full !justify-between md:!ui-justify-end">
      <span class="sr-only">MKM</span>
      <NuxtLink to="/" class="w-3xl md:w-[130px]" aria-label="MKM">
        <SvgLogo name="mkm-blue" class-name="w-3xl md:w-[130px]" data-test-id="logo" />
      </NuxtLink>
      <Stack
        :direction="flexRow ? 'row' : 'col'"
        gap="xs"
        :align="flexRow ? 'center' : 'end'"
        class="!justify-between"
        :class="flexRow ?? 'w-full !flex-row-reverse'"
      >
        <Stack gap="xs" align="center">
          <Stack v-if="iconName">
            <Icon
              :size="16"
              :name="iconName"
              :color="getColor('charcoal-default')"
              class-name="w-xs h-xs md:w-sm md:h-sm"
              :data-test-id="iconName"
            />
          </Stack>
          <Text
            tag="span"
            uppercase
            class-name="text-charcoal-default !font-semibold md:!font-semi !text-xs md:!text-md !leading-xs md:!leading-md mt-3xs"
            :data-test-id="titleTestId + '-title'"
          >
            {{ title }}
          </Text>
        </Stack>
        <Text
          v-if="subtitle"
          tag="span"
          :class="flexRow ?? 'text-right'"
          class-name="hidden md:block text-charcoal-default !font-semibold md:!font-semi !text-sm md:!text-base !leading-xs md:!leading-md"
          data-test-id="simpleHeader-Subtitle"
        >
          {{ subtitle }}
        </Text>
      </Stack>
    </Stack>
    <Text v-if="subtitle" tag="span" class-name="md:hidden text-charcoal-default !font-semibold !text-xs !leading-xs">
      {{ subtitle }}
    </Text>
  </Stack>
</template>
<script lang="ts" setup>
import { SimpleHeaderProps } from "./types";
import { getColor } from "mkm-avengers";

const { title, iconName, subtitle, flexRow } = withDefaults(defineProps<SimpleHeaderProps>(), {
  flexRow: false,
});

const titleTestId = computed(() => {
  if (!title) return "";

  return title.replace(/\s+/g, "");
});
</script>
